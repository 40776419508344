// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$masip-front-primary: mat.define-palette(mat.$indigo-palette);
$masip-front-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$masip-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$masip-front-theme: mat.define-light-theme((color: (primary: $masip-front-primary,
        accent: $masip-front-accent,
        warn: $masip-front-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($masip-front-theme);

/* Swiper */
@import "swiper/scss";
@import url("assets/fonts/roboto/stylesheet.css");

:root {

  //foxies colorway
  --color-primary: #072A2A;
  --color-secondary: #062E30;
  --color-tertiary: #0D6464;
  --color-quaternary: #0A4444;
  --color-modal-blue: #0A5555;
  --color-link: #3B84C1;
  --color-link-light: #DEF0F9;
  --date-blue-button: #0E7D7D;
  --color-accent: #0BD6D9;
  --accent-gradient: linear-gradient(98deg, #06B7B9 0%, #0BD6D9 100%);
  --color-white: #ECECEC;
  --color-grey: #A5A6AA;
  --color-success: #44CC84;
  --color-warning: #06B7B9;
  --color-danger: #FF5D68;

  //masip colorway
  // --color-primary: #061A2D;
  // --color-secondary: #0E2134;
  // --color-tertiary: #20486A;
  // --color-quaternary: #142B41;
  // --color-modal-blue: #183A56;
  // --color-link: #3B84C1;
  // --color-link-light: #DEF0F9;
  // --date-blue-button: #2D6594;
  // --color-accent: #FFCA46;
  // --accent-gradient: linear-gradient(98deg, #E19B1B 0%, #FFD56D 100%);
  // --color-white: #ECECEC;
  // --color-grey: #A5A6AA;
  // --color-success: #44CC84;
  // --color-warning: #FDA400;
  // --color-danger: #FF5D68;
  --border-radius-round: 25px;
  --border-radius-button: 6px;
  --border-radius-container: 30px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-drawer-container {
  background-color: var(--color-quaternary) !important;
}

.big-dialog {
  max-width: 823px !important;
  min-width: 823px !important;
  width: 43vw !important;
  height: 73vh !important;
  max-height: 780px !important;

  .mat-mdc-dialog-surface {
    background: var(--color-primary) !important;
    border-radius: var(--border-radius-container) !important;
    overflow: hidden;
  }

  .dialog-header {
    padding: 34px 40px 37px 37px;
    flex: 0 1 auto;

    .header-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .title {
        color: var(--color-white);
        font-size: 22px;
        font-family: 'Roboto-Regular';
      }

      i {
        font-size: 23px;
        color: var(--color-white);
        cursor: pointer;
      }
    }

    ::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }

    .header-buttons-tabs {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
      overflow-x: auto;
      padding-bottom: 5px;

      app-accent-button {

        .accent-button-wrapper {
          padding-left: 20px;
          padding-right: 20px;
          height: 40px;
        }
      }

    }

  }

  .dialog-footer {
    height: 114px;
    flex: 0 1 114px;
    background: var(--color-modal-blue);
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
    width: 100%;
    padding: 32px 35px;

    app-accent-button {
      width: 200px;

      .accent-button-wrapper {
        height: 50px;
      }
    }

    .apply-many-button {
      color: var(--color-accent);
      border: 1px solid var(--color-accent);
      padding: 13px 26px;
      border-radius: var(--border-radius-button);
      width: 200px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .text {
        font-size: 18px;
        font-family: 'Roboto-Medium';
      }
    }

    .disabled {
      background: var(--color-grey);
      color: var(--color-quaternary);
      cursor: default;
    }
  }
}

.success-dialog {
  max-width: 556px !important;
  width: 80vw !important;
  height: 38vh !important;
  max-height: 360px !important;
  position: relative;



  .mat-mdc-dialog-surface {
    background: var(--color-primary) !important;
    border-radius: var(--border-radius-container) !important;
    overflow: hidden;
    padding: 62px 116px 36px 116px;
  }

}

.add-more-dialog {
  max-width: 556px !important;
  width: 80vw !important;
  height: 60vh !important;
  max-height: 500px !important;
  position: relative;



  .mat-mdc-dialog-surface {
    background: var(--color-primary) !important;
    border-radius: var(--border-radius-container) !important;
    overflow: hidden;
    padding: 62px 56px 36px 56px;
    padding-right: 56px !important;
  }

}

.admin-config-dialog {
  max-width: 556px !important;
  width: 80vw !important;
  height: 60vh !important;
  max-height: 500px !important;
  position: relative;



  .mat-mdc-dialog-surface {
    background: var(--color-primary) !important;
    border-radius: var(--border-radius-container) !important;
    overflow: hidden;
    padding: 62px 116px 36px 116px;
  }

}

.mat-ripple-element {
  background: transparent !important;
}

.mdc-checkbox__ripple {
  display: none;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: var(--color-accent) !important;
  background-color: var(--color-accent) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: var(--color-primary) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: var(--color-white) !important;
  border-width: 1px !important;
}

.mdc-label {
  font-size: 14px;
  font-family: 'Roboto-Regular';
  color: var(--color-white);
}

.mdc-radio__ripple {
  display: none;
}

mat-radio-button.mat-accent {

  --mat-radio-ripple-color: transparent !important;
  --mat-radio-checked-ripple-color: transparent !important;
  --mdc-radio-selected-icon-color: var(--color-accent) !important;
  --mdc-radio-selected-hover-icon-color: var(--color-accent) !important;
  --mdc-radio-selected-focus-icon-color: var(--color-accent) !important;
  --mdc-radio-selected-pressed-icon-color: var(--color-accent) !important;
  --mdc-radio-unselected-icon-color: #ffffff !important;
  --mdc-radio-unselected-hover-icon-color: #ffffff !important;
}

mat-radio-button {

  ::ng-deep.mdc-label {
    color: var(--color-white);
    font-size: 14px;
    font-family: 'Roboto-Regular';
  }

}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #ffffff !important;
}

.error-message {
  width: 100%;
  text-align: center;

  p {
    color: var(--color-white);
    font-size: 18px;
    font-family: 'Roboto-Regular';
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  mat-spinner {
    width: 50px !important;
  }

  p {
    font-size: 16px;
    color: var(--color-white);
    font-family: 'Roboto-Regular';
  }
}

html {
  --mdc-circular-progress-active-indicator-color: var(--color-accent);

  --mat-option-selected-state-label-text-color: var(--color-accent);

  --mat-datepicker-calendar-period-button-icon-color: var(--color-white);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--color-white);
  --mat-datepicker-calendar-date-today-outline-color: var(--color-accent);
  --mat-datepicker-calendar-date-text-color: var(--color-white);
  --mat-datepicker-calendar-container-background-color: var(--color-primary);
  --mat-datepicker-calendar-header-text-color: var(--color-grey);
  --mat-datepicker-calendar-body-label-text-color: var(--color-grey);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--color-accent);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--color-primary);
  --mat-datepicker-calendar-date-in-range-state-background-color: #0bd6d931;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: transparent;
  --mat-datepicker-calendar-date-hover-state-background-color: #0bd6d979;
  --mat-datepicker-calendar-date-focus-state-background-color: #0bd6d979;

  --mdc-switch-unselected-track-color: var(--color-quaternary);
  --mdc-switch-unselected-hover-track-color: var(--color-quaternary);
  --mdc-switch-unselected-focus-track-color: var(--color-quaternary);
  --mdc-switch-unselected-pressed-track-color: var(--color-quaternary);
  --mdc-switch-unselected-handle-color: var(--color-white);
  --mdc-switch-unselected-focus-handle-color: var(--color-white);
  --mdc-switch-unselected-hover-handle-color: var(--color-white);
  --mdc-switch-unselected-pressed-handle-color: var(--color-white);


  --mdc-radio-unselected-focus-icon-color: var(--color-white);
}

html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-track-color: var(--date-blue-button);
  --mdc-switch-selected-handle-color: var(--color-white);
  --mdc-switch-selected-hover-handle-color: var(--color-white);
  --mdc-switch-selected-focus-handle-color: var(--color-white);
  --mdc-switch-selected-hover-track-color: var(--date-blue-button);
  --mdc-switch-selected-focus-track-color: var(--date-blue-button);
  --mdc-switch-selected-pressed-track-color: var(--date-blue-button);
  --mdc-switch-selected-pressed-handle-color: var(--color-white);
  --mdc-switch-selected-pressed-state-layer-color: var(--date-blue-button);
  --mdc-switch-selected-hover-state-layer-color: var(--date-blue-button);
  --mdc-switch-selected-focus-state-layer-color: var(--date-blue-button);
}

.mat-drawer-container {
  height: 100%;
}

.table-scroll {
  width: 100%;
  overflow-x: auto;

  @media (max-width: 1024px) {
    overflow-x: auto;
    width: 100%;
    margin-bottom: var(--space-blocks);

  }

  .data-table {
    width: 100%;
    // min-width: 820px;
  }


}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-tertiary);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-white);
  border-radius: 16px;
}

.mat-calendar-period-button {
  color: var(--color-white) !important;
}

mat-slide-toggle {
  svg {
    display: none !important;
  }

  button {
    width: 43px !important;
  }

  .mdc-switch__handle {
    width: 15px !important;
    height: 15px !important;
  }

  .mdc-switch__handle-track {
    width: calc(100% - 25px) !important;
    margin-left: 5px !important;
  }

  .mdc-switch__track {
    height: 24px !important;
    width: 43px !important;
    border-radius: 25px !important;
    border: 2px solid var(--color-white) !important;
  }
}

.custom-select {
  position: relative;

  p {
    margin: 0;
  }

  .text {
    position: absolute;
    left: 10px;
    color: var(--color-tertiary);
    font-size: 14px;
    font-family: 'Roboto-Regular';
    top: calc(50% - 10px);
  }

  .icon {
    color: var(--color-tertiary) !important;
    font-size: 14px !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
  }

  mat-select {
    width: 205px;
    height: 35px;
    background: var(--color-white);
    border: 1px solid var(--color-tertiary);
    border-radius: 6px;
    padding: 8px 10px;
    color: var(--color-tertiary) !important;

    .mat-mdc-select-arrow-wrapper {
      display: none !important;
    }

    .mat-mdc-select-min-line {
      position: relative;
      bottom: 5px;
      color: var(--color-tertiary) !important;
    }

  }


}

.mat-mdc-option {
  color: var(--color-tertiary) !important;

  mat-pseudo-checkbox {
    display: none !important;
  }
}

.mat-mdc-select-panel {
  width: 203px !important;
  position: fixed !important;
  margin-left: -10px;
}

@media (max-width: 1024px) {
  .big-dialog {
    width: 100vw !important;
    height: 80vh !important;
    min-width: unset !important;

    .dialog-header .header-title-wrapper .title {
      width: 80% !important;
      margin-bottom: 10px;
    }

    .dialog-footer .apply-many-button {
      padding: 10px 10px !important;

      .text {
        font-size: 14px !important;
        text-align: center !important;
      }
    }

    .dialog-footer {
      .accent-button-wrapper .text {
        font-size: 16px !important;
      }
    }

    .custom-select {
      flex-direction: column;
    }

    .configure-telephone-wrapper .options-wrapper .line {
      flex-direction: column;
    }

    .black-white-list-line {
      flex-direction: row !important;
    }

    .timetable-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .timetable {
      width: 500px !important;
    }


    .timetable .line {
      flex-direction: row !important;
    }

    .double-input {
      flex-direction: column;
    }

    .white-black-list {
      flex-direction: column;

      div {

        flex-direction: column;
      }
    }

    .timetable .headline .day {
      text-align: center !important;
    }

    div.mat-mdc-select-panel {
      max-height: 180px !important;
    }

    .config-line-resp {
      flex-wrap: wrap;

      .custom-select {
        margin-left: auto;
        width: 205px !important;
      }

      input,
      div {
        margin-left: auto;
      }
    }
  }

  .admin-config-dialog {

    .mat-mdc-dialog-surface {
      padding: 20px;
    }

  }

}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

mat-tooltip-component {
  .mdc-tooltip__surface {
    padding: 8px !important;
    text-align: center !important;
    font-family: 'Roboto-Regular' !important;
    background: var(--color-quaternary) !important;
    border: 1px solid var(--color-tertiary) !important;
    max-width: 300px !important;
  }

}