@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto Regular'), local('Roboto-Regular'),
    url('Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}